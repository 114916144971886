import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

import { ImageCard } from '../components/card';

const IllustrationTemplate = props => {
	const { title, alt_text: description } = props.data.wordpressWpIllustrations.featured_media;

	return (
		<Layout location={props.location}>
			<SEO title={title} description={`${description || title} - an Illustration by Vanessa Toye`} />
			<ImageCard node={props.data.wordpressWpIllustrations} />
		</Layout>
	);
};
export default IllustrationTemplate;

export const illustrationQuery = graphql`
	query($id: String!) {
		wordpressWpIllustrations(id: { eq: $id }) {
			featured_media {
				localFile {
					childImageSharp {
						fluid(maxWidth: 1280) {
							...GatsbyImageSharpFluid_withWebp_tracedSVG
						}
						sizes {
							presentationHeight
							presentationWidth
						}
					}
				}
				title
				alt_text
			}
		}
	}
`;
